import React from "react"
import Layout from "../components/layout"

const NotFoundPage = ({ pageContext }) => {
  //console.log("pageContext 404 template=", pageContext)
  pageContext.breadcrumb={location:"/404/"}
  return (
    <Layout seotitle="404: Not found"
      pageContext={pageContext}>
      <div dangerouslySetInnerHTML={{ __html: pageContext.html }}/>
    </Layout>
  )
}

export default NotFoundPage
